import React from 'react'
import { Button } from 'react-bootstrap'
import PopUpController from './popUp/PopUpController'

const PopUpTestPage = () => {
    return ( 
        // 1- категория, 2 - товар, 3- страница, 4 - акция, 5 -главная страница акция
        <div className='flex gap-2 my-5'>
            <Button>
                тип 1 - картинка с инфо и кнопкой
            </Button>
            <Button>
                тип 2 - текст
            </Button>
            <Button>
                тип 3 - видео
            </Button>

            <PopUpController 
            
            />
        </div>
    )
}

export default PopUpTestPage