//React
import React, { Component } from 'react';
import { Container, Row, Col, Breadcrumb, Tabs, Tab, Button } from 'react-bootstrap';

//My Components
import MenuCatalog from "./components/MenuCatalog2";
import Slider from './components/SliderPromo';
import CountDown from './components/CountDown2';

//SCSS
import "../../scss/components/pages/home/__pageHome.scss";
import "../../scss/components/pages/home/dropdownProducts.scss";
import '../../scss/components/pages/home2/__home2.scss';
import axios from "axios";
import { Link } from 'react-router-dom';
import Journal from "./components/Journal";
import PopUpController from '../popUp/PopUpController';

class PagePromo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      banners: [],
      blocks: [],
      newspapers: null,
      stock_offer: null,
      stock_offer_other: null,
      stock: null,
      sidebar: [],
      page: 1,
      per_page_original: 1,
      per_page: 11,
      total_pages: 1,
      total_offers: 0,
      toggle_aside: false,
      show: false,
      newspaperId: 0,
      newspaperCount: 0
    };
    this.onHover = this.onHover.bind(this);
    this.onOut = this.onOut.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.loadMoreOffers = this.loadMoreOffers.bind(this);
    this.toggleAside = this.toggleAside.bind(this);
    this.toggleNewspaper = this.toggleNewspaper.bind(this);
  }

  toggleAside(e) {
    this.setState(prevState => ({
      toggle_aside: !prevState.toggle_aside
    }));
  }

  loadMoreOffers() {
    this.setState({
      per_page: this.state.per_page + this.state.per_page_original
    })
  }

  toggleNewspaper(id, count) {
    this.setState({
      show: !this.state.show,
      newspaperId: id,
      newspaperCount: count
    }, () => {
      if (this.state.show) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    })
  }

  mouseInsideTimeout = null;

  showMenu() {
    this.setState(prevState => ({
      isToggleOnHover: !prevState.isToggleOnHover
    }));
    //this.catalogBtn.click()
    document.getElementById('catalog-btn').click()
  }

  onHover() {
    this.mouseInsideTimeout = setTimeout(this.showMenu, 300);
  }

  onOut() {
    clearTimeout(this.mouseInsideTimeout)
  }

  componentDidMount() {
    document.title = 'Галактика | Акции'

    axios.get(`${process.env.REACT_APP_API_BASE}/new-stocks/all-stocks`)
      .then(response => {
        const data = response.data.data;
        this.setState({
          title: data.title,
          banners: data.big_baners,
          blocks: data.block_baner,
          newspapers: data.newspapers,
          stock_offer: data.stock_offer,
          stock_offer_other: data.stock_offer_other,
          stock: data.stock,
        }, () => {
          // console.log(this.state.newspapers);
          this.setState({
            total_offers: this.state.stock_offer.items.length,
            total_pages: Math.ceil(this.state.stock_offer.items.length / this.state.per_page)
          })
        })
        let metaTitle, metaDescription, robotsIndex, robotsFollow
        if (data.meta_tags && data.meta_tags.seo_title && data.meta_tags.seo_title !== 'null') {
          metaTitle = data.meta_tags.seo_title
        }
        if (data.meta_tags && data.meta_tags.meta_discription && data.meta_tags.meta_discription !== 'null') {
          metaDescription = data.meta_tags.meta_discription
        }
        if (data.meta_tags && data.meta_tags.robots_index) {
          robotsIndex = data.meta_tags.robots_index
        }
        if (data.meta_tags && data.meta_tags.robots_follow) {
          robotsFollow = data.meta_tags.robots_follow
        }

        if (metaTitle) document.title = metaTitle;
        else document.title = data.block_baner[0].name + ' | Галактика';
        if (metaDescription) {
          if (!document.querySelector('meta[name="description"]')) {
            let metaD = document.createElement('meta')
            metaD.name = 'description'
            metaD.content = metaDescription
            document.querySelector('head').appendChild(metaD)
          } else {
            document.querySelector('meta[name="description"]').setAttribute('content', metaDescription)
          }
        }
        document.querySelectorAll('meta[name="robots"]').forEach((el) => {
          document.querySelector('head').removeChild(el)
        })
        if (robotsIndex || robotsFollow) {
          let metaD = document.createElement('meta')
          metaD.name = 'robots'
          let robotsContent = ''
          if (robotsIndex === 'yes') {
            robotsContent += 'index'
            if (robotsFollow === 'yes') {
              robotsContent += ', follow'
            }
            if (robotsFollow === 'no') {
              robotsContent += ', nofollow'
            }
          } else if (robotsIndex === 'no') {
            robotsContent += 'noindex'
            if (robotsFollow === 'yes') {
              robotsContent += ', follow'
            }
            if (robotsFollow === 'no') {
              robotsContent += ', nofollow'
            }
          } else {
            if (robotsFollow === 'yes') {
              robotsContent += 'follow'
            }
            if (robotsFollow === 'no') {
              robotsContent += 'nofollow'
            }
          }
          metaD.content = robotsContent
          document.querySelector('head').appendChild(metaD)
        }
      })
      .catch(error => {
        console.error(error);
      })
    axios.get(`${process.env.REACT_APP_API_BASE}/new-stocks/stocks-in-sidebar`)
      .then(response => {
        const data = response.data.data;
        this.setState({
          sidebar: data
        }, () => {
          // console.log(this.state);
        })
      })
      .catch(error => {
        console.error(error);
      })
  }

  getLink(linkObj) {

    if (typeof linkObj === 'string') {
      if (linkObj.indexOf("https://galaktika.me") !== -1)
        return linkObj.replace("https://galaktika.me/", '/')
      if (linkObj.indexOf("https://avoidrandomusers99.galaktika.me/") !== -1)
        return linkObj.replace("https://avoidrandomusers99.galaktika.me/", '/')
      return '/' + linkObj
    }

    if (!linkObj || (linkObj.type !== 'prod' && linkObj.type !== 'cat')) return '/'

    if (linkObj.type === 'prod') return '/product/' + linkObj.name_slug
    if (linkObj.type === 'cat' && linkObj.type_cat === 'have_cat') return '/category/' + linkObj.name_slug
    if (linkObj.type === 'cat' && linkObj.type_cat === 'no_have_cat') return '/catalog/' + linkObj.name_slug
  }

  getDays(endString) {
    let now = new Date()
    let end = new Date(endString)
    return end > now ? Math.floor((end - now) / (24 * 3600 * 1000)) : 0
  }

  getDaysWord(w) {
    if (w === 1 || (w % 10 === 1 && w !== 11)) return 'День'
    else if ((w > 0 && w < 5) || ((w % 10 > 1 && w % 10 < 5) && w > 20)) return 'Дня'
    else return 'Дней'
  }

  getRestWord(w) {
    if (w === 1 || (w % 10 === 1 && w !== 11)) return 'Остался'
    else return 'Осталось'
  }

  render() {
    // const clickBtn = this.state.isToggleOn;
    return (
      <>
        <main className='promo'>
          <PopUpController
            sendBody={{
              id: null,
              type: 5,
            }}
            pageSlug={'page-promo-main'}
          />
          <Container>
            <div className="promo-inner">
              <aside className={`promo-aside ${this.state.toggle_aside ? 'open' : ''}`}>
                <div className="promo-aside_header">
                  <h4>Навигация по акциям</h4>
                  <Button onClick={this.toggleAside} variant="simple" className="btn_close">
                    <span aria-hidden="true">×</span>
                  </Button>
                </div>
                <div className="promo-aside--list border">
                  <div className="promo-aside--list_header">
                    <h6>
                      Все акции
                    </h6>
                  </div>
                  <div className="promo-aside--list_body">
                    <ul>
                      {this.state.sidebar.length > 0 &&
                        this.state.sidebar.map((s, i) => (
                          <li key={i} onClick={this.toggleAside}>
                            {s.type !== 6 && (<Link to={`/promo/${s.name_slug}`}>
                              {s.name}
                            </Link>)}
                            {s.type === 6 && (<Link to={`/promo-brand/${s.name_slug}`}>
                              {s.name}
                            </Link>)}
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
                <div className="promo-categories">
                  <MenuCatalog />
                </div>
              </aside>
              <main className="promo-content">
                <Container>
                  <Breadcrumb>
                    <li className={'breadcrumb-item'}>
                      <Link to={`/`}>
                        <i className="i-home" />
                      </Link>
                    </li>
                    <li className={'breadcrumb-item'}>
                      <span>
                        {this.state.title}
                      </span>
                    </li>
                  </Breadcrumb>
                  <Button variant="primary" onClick={this.toggleAside} className="btn_promo-aside">Навигация по
                    акциям</Button>
                  <h3 className="page-subtitle">
                    {this.state.title}
                  </h3>
                  <Row className="promo-block ">
                    <Col xs={12} className="products promo-product">
                      {this.state.banners.length > 0 &&
                        <Slider items={this.state.banners} />
                      }
                    </Col>
                  </Row>
                  {this.state.blocks && this.state.blocks.map((block, index) => (
                    <React.Fragment key={index}>
                      {
                        block.type === 1 && (
                          <Row className="promo-block ">
                            <Col xs={12} xl={7} className="products promo-product">
                              {block.baners.length > 0 &&
                                <Slider items={block.baners} />
                              }
                            </Col>
                            <Col xs={12} xl={5}>
                              <CountDown
                                name={block.title}
                                startDate={block.date_start_stock}
                                endDate={block.date_end_stock}
                                time_stock={block.time_stock}
                                button={block.button}
                                text_for_modal={block.text_for_modal}
                                link={block.link}
                                text_end={block.text_end}
                                status={block.status}
                              />
                            </Col>
                          </Row>
                        )
                      }
                      {
                        block.type === 2 && (
                          <Row className="promo-block ">
                            <Col xs={12} xl={7} className="products promo-product">
                              {block.baners.length > 0 &&
                                <Slider items={block.baners} />
                              }
                            </Col>
                            <Col xs={12} xl={5}>
                              <CountDown
                                name={block.title}
                                startDate={block.date_start_stock}
                                endDate={block.date_end_stock}
                                time_stock={block.time_stock}
                                link={block.link}
                                text_end={block.text_end}
                                status={block.status}
                              />
                            </Col>
                          </Row>
                        )
                      }
                      {
                        block.type === 3 && (
                          <Row className="promo-block ">
                            <Col xs={12} xl={7} className="products promo-product">
                              {block.baners.length > 0 &&
                                <Slider items={block.baners} />
                              }
                            </Col>
                            <Col xs={12} xl={5}>
                              <div className="timetable tac h-100">
                                {block.status === 'open' && (
                                  <>
                                    <h4 dangerouslySetInnerHTML={{ __html: block.title }} />
                                    <div dangerouslySetInnerHTML={{ __html: block.text }} />
                                    {/*<Link to={this.getLink(block.link)}>*/}
                                    {/*  Перейти в Акцию*/}
                                    {/*</Link>*/}
                                  </>
                                )}
                                {block.status === 'end' && (
                                  <h4 className='h-100 d-flex pb32 align-items-center justify-content-center'>
                                    <div dangerouslySetInnerHTML={{ __html: block.text_end }} />
                                  </h4>
                                )}
                              </div>
                            </Col>
                          </Row>
                        )
                      }
                    </React.Fragment>
                  ))}

                  {
                    this.state.newspapers &&
                    (
                      <div className="promo-section promo-newspapers">
                        <h4 className="products-slider__title">
                          {this.state.newspapers.icon && (
                            <img
                              src={`${process.env.REACT_APP_BACKEND}/uploads/sp/icons/${this.state.newspapers.icon}`}
                              alt="" />
                          )}
                          {!this.state.newspapers.icon && (
                            <img
                              src={`${process.env.REACT_APP_BACKEND}/site_image/blocks_icons/slider-newspaper.svg`}
                              alt="" />
                          )}
                          {this.state.newspapers.title}
                        </h4>
                        <Row>
                          {this.state.newspapers.items.length > 0 &&
                            this.state.newspapers.items.map((np, i) => (
                              <Col xs={6} xl={3} key={i}>
                                <a href="!#" className="promo-newspapers_item" onClick={(e) => {
                                  e.preventDefault()
                                  this.toggleNewspaper(np.id, np.pages)
                                }}>
                                  <img
                                    src={`${process.env.REACT_APP_BACKEND}/uploads/sp/newspapers/${np.id}/1.jpg`}
                                    alt="" className="img-fluid" />
                                </a>
                              </Col>
                            ))
                          }
                        </Row>
                        {this.state.show && (
                          <div
                            className='position-fixed d-flex flex-column align-items-center'
                            style={{
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100vh',
                              zIndex: 999,
                              background: 'rgba(0,0,0,.5)',
                              backdropFilter: 'blur(5px)',
                            }}
                          >
                            <div className='position-absolute'
                              style={{
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100vh',
                                zIndex: 999,
                              }}
                              onClick={() => {
                                this.setState({ show: false, newspaperId: 0, newspaperCount: 0 })
                                document.body.style.overflow = 'auto'
                              }}
                            />
                            <Journal close={() => {
                              this.setState({ show: false, newspaperId: 0, newspaperCount: 0 })
                              document.body.style.overflow = 'auto'
                            }} id={this.state.newspaperId} count={this.state.newspaperCount} />
                          </div>
                        )}
                        {/*{this.state.newspapers.items.length > 0 && (<Journal pages={this.state.newspapers.items}/>)}*/}
                      </div>
                    )
                  }
                  {this.state.stock_offer &&
                    (
                      <div className="promo-section promo-whitelist">
                        <h4 className="products-slider__title">
                          {!this.state.stock_offer.icon && (
                            <img
                              src={`${process.env.REACT_APP_BACKEND}/site_image/blocks_icons/slider-bag2.svg`}
                              alt="" />
                          )}
                          {this.state.stock_offer.icon && (
                            <img
                              src={`${process.env.REACT_APP_BACKEND}/uploads/sp/icons/${this.state.stock_offer.icon}`}
                              alt="" />
                          )}
                          {this.state.stock_offer.title}
                        </h4>
                        <Row>
                          {this.state.stock_offer.items.length > 0 &&
                            this.state.stock_offer.items.map((so, i) => (
                              <Col xs={6} xl={3} key={i}
                                style={{
                                  display: ((i + 1 > (this.state.page - 1) * this.state.per_page) && (i + 1 <= this.state.page * this.state.per_page))
                                    ? 'block' : 'none'
                                }}
                              >
                                <div className="promo-whitelist_item border position-relative">
                                  <Link to={this.getLink(so.link)}>
                                    <img
                                      src={`${process.env.REACT_APP_BACKEND}/uploads/sp/offers/${so.baner_url}`}
                                      alt=""
                                      className="img-fluid" />
                                  </Link>
                                  <div className="flex">
                                    {so.type === 1 &&
                                      <>
                                        <div className="info">
                                          <div className="date">
                                            <div dangerouslySetInnerHTML={{ __html: so.time_stock }} />
                                          </div>
                                          <Link to={this.getLink(so.link)}>
                                            <div dangerouslySetInnerHTML={{ __html: so.text_link }} />
                                          </Link>
                                        </div>
                                        <div className="leftime tac">
                                          {this.getRestWord(this.getDays(so.date_end_offer))}
                                          <h3 style={{ color: so.color_day }}>
                                            {
                                              this.getDays(so.date_end_offer)
                                            }
                                          </h3>
                                          {this.getDaysWord(this.getDays(so.date_end_offer))}
                                        </div>
                                      </>
                                    }
                                    {so.type === 2 &&
                                      <>
                                        <div className="info">
                                          <Link to={this.getLink(so.link)} className="h4">
                                            <div dangerouslySetInnerHTML={{ __html: so.text_link }} />
                                          </Link>
                                        </div>
                                        {/* <div className="leftime tac" style={{opacity: 0}}> */}
                                        <div className="leftime tac" style={{ display: 'none' }}>
                                          Осталось
                                          <h3>-</h3>
                                          дней
                                        </div>
                                      </>
                                    }
                                  </div>
                                  {so.status === 'end' &&
                                    (
                                      <Link to={this.getLink(so.link)}
                                        className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center pb32'
                                        style={{
                                          background: 'rgba(255,255,255,.5)',
                                          zIndex: 99,
                                          top: 0,
                                          left: 0
                                        }}>
                                        <img
                                          className={'w-100 h-100 mb-5'}
                                          style={{
                                            objectFit: 'contain'
                                          }}
                                          src={`${process.env.REACT_APP_BACKEND}/uploads/sp/offers/${so.baner_url_end}`}
                                          alt="" />
                                      </Link>
                                    )
                                  }
                                </div>
                              </Col>
                            ))
                          }

                        </Row>

                        <div className="catalog-list__navigation">
                          <div className="loadMore">
                            <button onClick={this.loadMoreOffers}
                              style={{
                                pointerEvents: (this.state.total_offers > this.state.per_page) ? 'all' : 'none',
                                opacity: (this.state.total_offers > this.state.per_page) ? 1 : .2
                              }}
                            >
                              Показать больше
                            </button>
                          </div>
                          {/*<div className="pagination">*/}
                          {/*    <ul className="pagination__wrap">*/}
                          {/*        <ul className="pag_arrows">*/}
                          {/*            <li className="pagination__item pag_arrows__item bt_pprev">*/}
                          {/*                    <span style={{opacity: .15}}>*/}
                          {/*                        <i className="i-arrow prev" style={{*/}
                          {/*                            display: 'inline-block',*/}
                          {/*                            transform: 'rotate(90deg) translateY(-14px)'*/}
                          {/*                        }} />*/}
                          {/*                        <i className="i-arrow prev"*/}
                          {/*                           style={{display: 'inline-block'}} />*/}
                          {/*                    </span>*/}
                          {/*                /!* <Link className={`link`} to={`/catalog/${this.props.match.params.slug}`} onClick={() => { this.changePage(1) }}>*/}
                          {/*                        <i className="i-arrow prev" style={{ display: 'inline-block', transform: 'rotate(90deg) translateY(-14px)' }}></i>*/}
                          {/*                        <i className="i-arrow prev" style={{ display: 'inline-block' }}></i>*/}
                          {/*                    </Link> *!/*/}
                          {/*            </li>*/}
                          {/*            <li className="pagination__item pag_arrows__item bt_prev">*/}
                          {/*                    <span style={{opacity: .15}}>*/}
                          {/*                        <i className="i-arrow prev"><span /></i>*/}
                          {/*                    </span>*/}
                          {/*                /!* <Link className={`link`} to="!#">*/}
                          {/*                        <i className="i-arrow prev"><span></span></i>*/}
                          {/*                    </Link> *!/*/}
                          {/*            </li>*/}
                          {/*        </ul>*/}
                          {/*        <ul className="pag_list">*/}
                          {/*            /!* <li className="pagination__item"><b>...</b></li> *!/*/}
                          {/*            <li className="pagination__item">*/}
                          {/*                <Link className="link" to="!#">*/}
                          {/*                    <b>1</b>*/}
                          {/*                </Link>*/}
                          {/*            </li>*/}
                          {/*            <li className="pagination__item">*/}
                          {/*                <Link className="link" to="!#">*/}
                          {/*                    <b>2</b>*/}
                          {/*                </Link>*/}
                          {/*            </li>*/}
                          {/*            <li className="pagination__item">*/}
                          {/*                <Link className="link" to="!#">*/}
                          {/*                    <b>3</b>*/}
                          {/*                </Link>*/}
                          {/*            </li>*/}
                          {/*            <li className="pagination__item"><b>...</b></li>*/}
                          {/*            <li className="pagination__item">*/}
                          {/*                <Link className="link" to="!#">*/}
                          {/*                    <b>6</b>*/}
                          {/*                </Link>*/}
                          {/*            </li>*/}
                          {/*        </ul>*/}
                          {/*        <ul className="pag_arrows">*/}
                          {/*            <li className="pagination__item pag_arrows__item bt_next">*/}
                          {/*                <Link className={`link`} to="!#">*/}
                          {/*                    <i className="i-arrow next"><span /></i>*/}
                          {/*                </Link>*/}
                          {/*                /!* <span className={`link`} style={{ opacity: .15 }}>*/}
                          {/*                        <i className="i-arrow next"><span></span></i>*/}
                          {/*                    </span> *!/*/}
                          {/*            </li>*/}
                          {/*            <li className="pagination__item pag_arrows__item bt_nnext">*/}
                          {/*                <Link className={`link`} to="!#">*/}
                          {/*                    <i className="i-arrow next"*/}
                          {/*                       style={{display: 'inline-block'}} />*/}
                          {/*                    <i className="i-arrow next" style={{*/}
                          {/*                        display: 'inline-block',*/}
                          {/*                        transform: 'rotate(-90deg) translateY(-14px)'*/}
                          {/*                    }} />*/}
                          {/*                </Link>*/}
                          {/*                <span className={`link`} style={{ opacity: .15 }}>*/}
                          {/*                    <i className="i-arrow next" style={{ display: 'inline-block' }} />*/}
                          {/*                    <i className="i-arrow next" style={{ display: 'inline-block', transform: 'rotate(-90deg) translateY(-14px)' }} />*/}
                          {/*                </span>*/}
                          {/*            </li>*/}
                          {/*        </ul>*/}
                          {/*    </ul>*/}
                          {/*</div>*/}
                        </div>

                      </div>
                    )
                  }
                  {this.state.stock_offer_other &&
                    (
                      <div className="products-slider mb-5">
                        <h4 className="products-slider__title">
                          {!this.state.stock_offer_other.icon && (<img
                            src={`${process.env.REACT_APP_BACKEND}/site_image/blocks_icons/slider-hot.svg`}
                            alt="" />)}
                          {this.state.stock_offer_other.icon && (<img
                            src={`${process.env.REACT_APP_BACKEND}/uploads/sp/icons/${this.state.stock_offer_other.icon}`}
                            alt="" />)}
                          {this.state.stock_offer_other.title}
                        </h4>
                        <Tabs defaultActiveKey="tab01" id="uncontrolled-tab-example"
                          className="mb-3">
                          {this.state.stock_offer_other.items.length > 0 &&
                            this.state.stock_offer_other.items.map((st, i) => (
                              <Tab eventKey={`tab0${i + 1}`} title={<div dangerouslySetInnerHTML={{ __html: st.title }} />}
                                key={i}>
                                <Row>

                                  {/*<Col xs={12} xl={5}>*/}
                                  {/*    <img src="/img/temp/akciya/main01.jpg" alt=""*/}
                                  {/*         className="img-fluid"/>*/}
                                  {/*</Col>*/}

                                  <Col xs={12}>
                                    <p dangerouslySetInnerHTML={{ __html: st.text }} />
                                  </Col>
                                </Row>
                              </Tab>

                            ))
                          }
                        </Tabs>
                      </div>
                    )
                  }
                  {this.state.stock &&
                    (
                      <div className="products-slider">
                        <h4 className="products-slider__title">
                          {this.state.stock.icon && (<img
                            src={`${process.env.REACT_APP_BACKEND}/uploads/sp/icons/${this.state.stock.icon}`}
                            alt="" />)}
                          {this.state.stock.title}
                        </h4>
                        <Row>
                          <Col xs={12}>
                            <p dangerouslySetInnerHTML={{ __html: this.state.stock.text }} />
                          </Col>
                        </Row>
                      </div>
                    )
                  }

                </Container>
              </main>
            </div>
          </Container>
        </main>
      </>
    );
  }
}

export default PagePromo;
