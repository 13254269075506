//React
import React, { Component } from 'react';
import { Container, Row, Col, Breadcrumb, Button } from 'react-bootstrap';

//My Components
// import FilterPromo from "./components/FilterPromo";
// import WishList from "../main/ModalWishlist";
import SliderPromo from './components/SliderPromo';
// import Journal from './components/Journal';
import CountDown from './components/CountDown2';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
//SCSS
import "../../scss/components/pages/home/__pageHome.scss";
import "../../scss/components/pages/home/dropdownProducts.scss";
import '../../scss/components/pages/home2/__home2.scss';
import '../../scss/components/pages/categories/catChild.scss';
import '../../scss/components/pages/categories/filter.scss';
import axios from "axios";
import { Link } from 'react-router-dom';
import MenuCatalogSub from "./components/MenuCatalogSub3";
import Product from "./components/Product";
import ProductCard from '../categories/ProductCard';
import CartCtx from '../CartCtx';
import { isIterableArray } from '../block-slider/utils';
import PopUpController from '../popUp/PopUpController';


const Range = Slider.Range;
class PagePromoCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProductsHasVariable: false,
      blocks: [],
      data: null,
      sidebar: null,
      products: [],
      pagination: null,
      page: 1,
      crumb: '',
      ckText: '',
      stock_title: '',
      order_by: 1,
      isToggleOn: true,
      filterToggleOn: true,
      banners: [],
      current: {},
      filters: [],
      manufacturers: [],
      selectedFilters: [],
      selectedManufacturers: [],
      selectedColors: [],
      max_price: 0,
      price_from: 0,
      price_to: 0,
      colors: [],
      crumbs: [],
      trueRobots: null,
      chips: [],
      brandChips: [],
      isLoading: false
    };
    this.filterCheck = this.filterCheck.bind(this)
    this.brandCheck = this.brandCheck.bind(this)
    this.colorCheck = this.colorCheck.bind(this)
    this.changePage = this.changePage.bind(this)
    this.orderProducts = this.orderProducts.bind(this)
    this.resetFilters = this.resetFilters.bind(this)
    this.removeFilter = this.removeFilter.bind(this)
    this.removeBrand = this.removeBrand.bind(this)
    this.changePageAndHandle = this.changePageAndHandle.bind(this)
    this.handleClick = this.handleClick.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
  }

  static contextType = CartCtx;

  orderProducts(e) {
    this.setState({
      order_by: e.target.value,
      page: 1
    }, () => {
      this.changePage(1)
    })
  }

  getFilterName(id) {
    let arr = this.state.filters;
    let name = '';
    arr.map((f, i) => {
      f.filter_option.map((o, j) => {
        if (o.filter_option_id === id) name = o.filter_option_value
        return null
      })
      return null
    })
    return name;
  }

  changePageAndHandle() {
    this.changePage(1);
  }
  onLowerBoundChange = (e) => {
    this.setState({ price_from: +e.target.value });
  }
  onUpperBoundChange = (e) => {
    this.setState({ price_to: +e.target.value });
  }
  onSliderChange = (value) => {
    this.setState({
      price_from: value[0],
      price_to: value[1]
    });
  }
  handleApply = () => {
    const { min, max } = this.state;
    this.setState({ value: [min, max] });
  }
  resetFilters() {
    document.querySelectorAll('.filter-check').forEach((el, i) => {
      el.checked = false
    })
    this.setState({
      selectedFilters: [],
      selectedManufacturers: [],
      selectedColors: [],
      price_from: this.state.min,
      price_to: this.state.max
    })
    setTimeout(() => {
      this.changePage(1)
    }, 300)

  }
  filterCheck() {
    let checked = []
    document.querySelectorAll('.filter-check:checked').forEach((el, i) => {
      if (!el.classList.contains('brand-check')) checked.push(el.id)
    })
    this.setState({
      selectedFilters: checked
    }, () => {
      setTimeout(() => {
        this.changePage(1)
      }, 300)
    })
  }
  brandCheck() {
    let checked = []
    document.querySelectorAll('.brand-check:checked').forEach((el, i) => {
      checked.push(el.id.replace('m', ''))
    })
    this.setState({
      selectedManufacturers: checked
    }, () => {
      setTimeout(() => {
        this.changePage(1)
      }, 300)
    })
  }
  colorCheck() {
    let checked = []
    document.querySelectorAll('.color-check:checked').forEach((el, i) => {
      checked.push(el.id.replace('c', ''))
    })
    this.setState({
      selectedColors: checked
    }, () => {
      setTimeout(() => {
        this.changePage(1)
      }, 300)
    })
  }
  removeFilter(e) {
    document.getElementById(e.target.getAttribute('data-id')).checked = false
    this.filterCheck()
  }
  removeBrand(e) {
    document.getElementById(e.target.getAttribute('data-id')).checked = false
    this.brandCheck()
  }

  changePage(page) {
    let tmp = []
    if (this.state.selectedFilters.length > 0) {
      this.state.filters.forEach((f, i) => {
        f.filter_option.forEach((fo, j) => {
          this.state.selectedFilters.forEach((sf, k) => {
            if (fo.filter_option_id.toString() === sf.toString()) {
              tmp.push(fo)
              this.setState({
                chips: tmp
              }, () => {
                // console.log(this.state.chips)
              })
            }
          })
        })
      })
    } else {
      this.setState({
        chips: []
      })
    }
    tmp = []
    if (this.state.selectedManufacturers.length > 0) {
      this.state.manufacturers.forEach((m, i) => {
        this.state.selectedManufacturers.forEach((sm, j) => {
          if (m.id.toString() === sm) {
            tmp.push(m)
            this.setState({
              brandChips: tmp
            })
          }
        })
      })
    } else {
      this.setState({
        brandChips: []
      })
    }

    const selectedCenterId = this.context.selectedCenter ? this.context.selectedCenter.id : false
    const postData = {
      'slug_stock': this.props.match.params.stock,
      'slug_cat': this.props.match.params.category,
      'page': page,
      'arrayFilter': this.state.selectedFilters.length === 0 ? '' : this.state.selectedFilters.join(', '),
      'colorFilter': this.state.selectedColors.length === 0 ? '' : this.state.selectedColors.join(', '),
      'manufacturer': this.state.selectedManufacturers.length === 0 ? 'no' : this.state.selectedManufacturers.join(', '),
      'price_from': this.state.price_from,
      'price_to': this.state.price_to,
      'order_by': this.state.order_by,
      tc_id: selectedCenterId
    }
    this.setState({
      isLoading: true
    })
    axios.post(`${process.env.REACT_APP_API_BASE}/new-stocks/stock-catalog-product`, postData)
      .then(response => {
        this.setState({
          isProductsHasVariable: isIterableArray(response.data.data[0].filter(item => (item.color !== null))),
          products: response.data.data[0],
          filters: response.data.data[1],
          manufacturers: response.data.data[2],
          pagination: response.data.data[4],
          selectedFilters: response.data.data[5].filter,
          selectedManufacturers: response.data.data[5].manufacturer[0] === 'no' ? [] : response.data.data[5].manufacturer,
          selectedColors: response.data.data[5].filter,
          order_by: response.data.data[5].orderBy,
          price_from: response.data.data[5].price_from,
          price_to: response.data.data[5].price_to,
          min: response.data.data[6].minPrice,
          max: response.data.data[6].maxPrice,
          colors: response.data.data[3],
          page: page,
        }, () => {
          this.setState({
            isLoading: false
          })
          // console.log(this.state.manufacturers)
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  getProducts() {
    this.setState({
      isLoading: true
    })
    const selectedCenterId = this.context.selectedCenter ? this.context.selectedCenter.id : false
    const postData = {
      'slug_stock': this.props.match.params.stock,
      'slug_cat': this.props.match.params.category || 'brand',
      'page': this.state.page,
      'price_from': 0,
      'price_to': 'no',
      'manufacturer': 'no',
      'order_by': this.state.order_by,
      'arrayFilter': null,
      'colorFilter': null,
      tc_id: selectedCenterId
    }
    axios.post(`${process.env.REACT_APP_API_BASE}/new-stocks/stock-catalog-product`, postData)
      .then(response => {
        this.setState({
          isProductsHasVariable: isIterableArray(response.data.data[0].filter(item => (item.color !== null))),
          products: response.data.data[0],
          filters: response.data.data[1],
          manufacturers: response.data.data[2],
          pagination: response.data.data[4],
          selectedFilters: response.data.data[5].filter,
          selectedManufacturers: response.data.data[5].manufacturer[0] === 'no' ? [] : response.data.data[5].manufacturer,
          selectedColors: response.data.data[5].filter,
          order_by: response.data.data[5].orderBy,
          price_from: response.data.data[5].price_from,
          price_to: response.data.data[5].price_to,
          min: response.data.data[6].minPrice,
          max: response.data.data[6].maxPrice,
          colors: response.data.data[3],
          page: this.state.page,
        }, () => {
          this.setState({
            isLoading: false
          })
          // console.log(this.state.manufacturers)
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  componentDidMount() {
    document.title = 'Галактика | Акция'

    axios.get(`${process.env.REACT_APP_API_BASE}/new-stocks/stocks-in-sidebar`)
      .then(response => {
        const data = response.data.data;
        const filtered = data.filter(s => s.name_slug === this.props.match.params.stock)
        this.setState({
          crumb: filtered[0].name,
          stock_title: filtered[0].name,
        })
      })
      .catch(error => {
        console.error(error);
      })

    axios.get(`${process.env.REACT_APP_API_BASE}/new-stocks/stock-categories-sidebar/${this.props.match.params.stock}/${this.props.match.params.category}`)
      .then(response => {
        this.setState({
          sidebar: response.data.data
        }, () => {
          this.getProducts()
        })
      })
      .catch(error => {
        console.error(error);
      })
    axios.get(`${process.env.REACT_APP_API_BASE}/new-stocks/stock-baner/${this.props.match.params.stock}`)
      .then(response => {
        const data = response.data.data;
        this.setState({
          blocks: data.block_baner,
          // stock_title: data.block_baner[0].name,
          data: data,
        }, () => {

          if (this.state.data.text) {
            this.setState({
              ckText: this.state.data.text
            })
          }
          if (this.state.data.background_url) {
            document.body.style.backgroundImage = `url(${process.env.REACT_APP_BACKEND}uploads/sp/stocks/${this.state.data.background_url.replace(/\s+/, "%20")})`
            document.body.style.backgroundPosition = 'center'
            // document.body.style.backgroundSize = 'cover'
          }
        })

        let metaTitle, metaDescription, robotsIndex, robotsFollow
        if (data.meta_tags && data.meta_tags.seo_title && data.meta_tags.seo_title !== 'null') {
          metaTitle = data.meta_tags.seo_title
        }
        if (data.meta_tags && data.meta_tags.meta_discription && data.meta_tags.meta_discription !== 'null') {
          metaDescription = data.meta_tags.meta_discription
        }
        if (data.meta_tags && data.meta_tags.robots_index) {
          robotsIndex = data.meta_tags.robots_index
        }
        if (data.meta_tags && data.meta_tags.robots_follow) {
          robotsFollow = data.meta_tags.robots_follow
        }

        if (metaTitle) document.title = metaTitle;
        else document.title = data.block_baner[0].name + ' | Галактика';
        if (metaDescription) {
          if (!document.querySelector('meta[name="description"]')) {
            let metaD = document.createElement('meta')
            metaD.name = 'description'
            metaD.content = metaDescription
            document.querySelector('head').appendChild(metaD)
          } else {
            document.querySelector('meta[name="description"]').setAttribute('content', metaDescription)
          }
        }
        document.querySelectorAll('meta[name="robots"]').forEach((el) => {
          document.querySelector('head').removeChild(el)
        })
        if (robotsIndex || robotsFollow) {
          let metaD = document.createElement('meta')
          metaD.name = 'robots'
          let robotsContent = ''
          if (robotsIndex === 'yes') {
            robotsContent += 'index'
            if (robotsFollow === 'yes') {
              robotsContent += ', follow'
            }
            if (robotsFollow === 'no') {
              robotsContent += ', nofollow'
            }
          } else if (robotsIndex === 'no') {
            robotsContent += 'noindex'
            if (robotsFollow === 'yes') {
              robotsContent += ', follow'
            }
            if (robotsFollow === 'no') {
              robotsContent += ', nofollow'
            }
          } else {
            if (robotsFollow === 'yes') {
              robotsContent += 'follow'
            }
            if (robotsFollow === 'no') {
              robotsContent += 'nofollow'
            }
          }
          metaD.content = robotsContent
          document.querySelector('head').appendChild(metaD)
        }
        // document.querySelectorAll('.will-animate').forEach( (el, i) => {
        //     el.classList.add('fadeInBlock')
        // })

      })
      .catch(error => {
        console.error(error);
      })
  }

  componentWillUnmount() {
    document.body.style.backgroundImage = ''
  }

  getLink(linkObj) {

    if (typeof linkObj === 'string') {
      if (linkObj.indexOf("https://galaktika.me") !== -1)
        return linkObj.replace("https://galaktika.me/", '/')
      if (linkObj.indexOf("https://avoidrandomusers99.galaktika.me/") !== -1)
        return linkObj.replace("https://avoidrandomusers99.galaktika.me/", '/')
      return '/' + linkObj
    }

    if (!linkObj || (linkObj.type !== 'prod' && linkObj.type !== 'cat')) return '/'

    if (linkObj.type === 'prod') return '/product/' + linkObj.name_slug
    if (linkObj.type === 'cat' && linkObj.type_cat === 'have_cat') return '/category/' + linkObj.name_slug
    if (linkObj.type === 'cat' && linkObj.type_cat === 'no_have_cat') return '/catalog/' + linkObj.name_slug
  }

  getDays(endString) {
    let now = new Date()
    let end = new Date(endString)
    return end > now ? Math.floor((end - now) / (24 * 3600 * 1000)) : 0
  }

  handleClick() {
    // document.body.classList.toggle('bfix');
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
  }

  toggleFilter(e) {
    this.setState(prevState => ({
      filterToggleOn: !prevState.filterToggleOn
    }));
    let parent;
    if (e.target.type === 'button') parent = e.target.parentNode
    else parent = e.target.parentNode.parentNode
    parent.classList.toggle('collapsed')
  }
  render() {
    return (
      <>
        <main className='promo'>
          <PopUpController
            sendBody={{
              id: null,
              type: 4,
              slug: this.props.match.params.category
            }}
            pageSlug={this.props.match.params.category}
          />
          <Container>
            <Breadcrumb>
              <li className={'breadcrumb-item'}>
                <Link to={`/`}>
                  <i className="i-home" />
                </Link>
              </li>
              <li className={'breadcrumb-item'}>
                <Link to={`/promo`}>
                  Все акции
                </Link>
              </li>
              {this.state.crumb && (<li className={'breadcrumb-item'}>
                <Link to={`/promo/${this.props.match.params.stock}`}>
                  {this.state.crumb}
                </Link>
              </li>)}
              {this.state.sidebar && this.state.sidebar.category_up.length > 0 &&
                this.state.sidebar.category_up.map((c, i) => (
                  <li className={'breadcrumb-item'} key={i}>
                    <Link to={`/promo/${this.props.match.params.stock}/${c.name_slug}`}>
                      {c.name}
                    </Link>
                  </li>
                ))
              }
              <li className={'breadcrumb-item'}>
                <span>
                  {this.state.sidebar && this.state.sidebar.category.name}
                </span>
              </li>
            </Breadcrumb>
            {this.state.blocks && this.state.blocks.map((block, index) => (
              <React.Fragment key={index}>
                {
                  block.type === 1 && (
                    <Row className="mb-4">
                      <Col xs={12} xl={7} className="products promo-product">
                        {block.baners.length > 0 &&
                          <SliderPromo items={block.baners} />
                        }
                      </Col>
                      <Col xs={12} xl={5}>
                        <CountDown
                          name={block.title}
                          startDate={block.date_start_stock}
                          endDate={block.date_end_stock}
                          time_stock={block.time_stock}
                          button={block.button}
                          text_for_modal={block.text_for_modal}
                          link={block.link}
                          text_end={block.text_end}
                          status={block.status}
                        />
                      </Col>
                    </Row>
                  )
                }
                {
                  block.type === 2 && (
                    <Row className="mb-4">
                      <Col xs={12} xl={7} className="products promo-product">
                        {block.baners.length > 0 &&
                          <SliderPromo items={block.baners} />
                        }
                      </Col>
                      <Col xs={12} xl={5}>
                        <CountDown
                          name={block.title}
                          startDate={block.date_start_stock}
                          endDate={block.date_end_stock}
                          time_stock={block.time_stock}
                          link={block.link}
                          text_end={block.text_end}
                          status={block.status}
                        />
                      </Col>
                    </Row>
                  )
                }
                {
                  block.type === 3 && (
                    <Row>
                      <Col xs={12} xl={7} className="products promo-product">
                        {block.baners.length > 0 &&
                          <SliderPromo items={block.baners} />
                        }
                      </Col>
                      <Col xs={12} xl={5}>
                        <div className="timetable tac h-100">
                          {block.status === 'open' && (
                            <>
                              <h4 dangerouslySetInnerHTML={{ __html: block.title }} />
                              <div dangerouslySetInnerHTML={{ __html: block.text }} />
                              {/*<Link to={this.getLink(block.link)}>*/}
                              {/*  Перейти в Акцию*/}
                              {/*</Link>*/}
                            </>
                          )}
                          {block.status === 'end' && (
                            <h4 className='h-100 d-flex pb32 align-items-center justify-content-center'
                              dangerouslySetInnerHTML={{ __html: block.text_end }} />
                          )}
                        </div>
                      </Col>
                    </Row>
                  )
                }
              </React.Fragment>
            ))}
            {/*<Journal/>*/}
            <div className="promo-content_header border">
              <h3 className="mtb10">
                {this.props.match.params.category && (<>Акционные товары категории</>)}
                {!this.props.match.params.category && (<>Товары акции</>)}
                &nbsp;
                <span className="op03">
                  {this.state.sidebar && this.state.sidebar.category.name}
                  {!this.props.match.params.category && this.state.crumb}
                </span>
                &nbsp;
                <span className="promo-content_header-qty f10 fw600 ml10">
                  {this.state.pagination && this.state.pagination.total} товаров
                </span>
              </h3>
              <div className="f catalog__settings">
                <div className="filter-selection f">
                  <div className="filter-mobile">
                    <button className="btn btn-primary" onClick={this.handleClick}>Фильтры</button>
                  </div>
                  <div className="filter-selection__label">
                    <p>Выбранные фильтры:</p>
                  </div>
                  <div className='filter-selection__list'>
                    <div className='filter-selection__item'>
                      <button className="filter-selection__btn reset" onClick={this.resetFilters}>Сбросить</button>
                    </div>
                    {this.state.chips.length > 0 && this.state.chips.map((c, i) => (
                      <div className='filter-selection__item' key={i}>
                        <button className="filter-selection__link" key={i} data-id={c.filter_option_id} onClick={this.removeFilter}>
                          {c.filter_option_value}
                        </button>
                      </div>
                    ))}
                    {this.state.brandChips.length > 0 && this.state.brandChips.map((c, i) => (
                      <div className='filter-selection__item' key={i}>
                        <button className="filter-selection__link" key={i} data-id={'m' + c.id} onClick={this.removeBrand}>
                          {c.name}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="catalog-view mla">
                  <div className="catalog-view__wrap">
                    <div className="catalog-view__sort">
                      <select value={this.state.order_by} id='order' className="select-custom"
                        onChange={this.orderProducts}>
                        <option value='1'> По умолчанию</option>
                        <option value='2'> От дешевых к дорогим</option>
                        <option value='3'> От дорогих к дешевым</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="promo-inner">
              <aside className={`catalog-filter ${this.state.isToggleOn ? '' : 'show'}`}>
                <div className="catalog-filter__overlay" onClick={this.handleClick} />
                <div className="catalog-filter__wrap" >
                  <div className="catalog-filter__promo">
                    <Link to={`/promo`} className="flex align-items-center mt10">
                      <i className="i-home" />
                      <b className="flex fg ttu ml-1 mt-1">Все акции</b>
                    </Link>
                    <div className="promo-categories">

                      {this.state.sidebar && (
                        <MenuCatalogSub sidebar={this.state.sidebar} stock={this.props.match.params.stock}
                          category={this.props.match.params.category} stock_title={this.state.stock_title} />
                      )}
                    </div>
                  </div>
                  <div className="flex catalog-filter__header">
                    <Button variant="link" className="filter_m_toggle" onClick={this.handleClick}>Фильтры</Button>
                    <div className="filter-selection__label">
                      <p>Выбрано товаров: <span>{this.state.pagination && this.state.pagination.total}</span></p>
                    </div>
                  </div>
                  <div className="catalog-filter__inner">

                    <div className="catalog-filter__footer flex top-button">
                      <Button className="filter-set" onClick={this.changePageAndHandle}>Показать</Button>
                    </div>
                    {this.state.manufacturers.length > 0 &&
                      <div className={`filter-block ${this.state.filterToggleOn ? '' : ''}`}>
                        <Button variant="link filter-block__title" onClick={this.toggleFilter}>
                          <i className="i-arrow-b" />
                          Производители
                        </Button>
                        <div className="filter-block__inner">
                          <div className="checkbox-filter">
                            {this.props.match.params.category && this.state.manufacturers.map((opt, index) => {
                              return (
                                <div className="checkbox-filter__item" key={index}>
                                  <input className="custom-checkbox filter-check brand-check"
                                    type="checkbox"
                                    id={'m' + opt.id}
                                    onChange={this.brandCheck} />
                                  <label htmlFor={'m' + opt.id} >
                                    {opt.name}
                                  </label>
                                </div>
                              )
                            })}
                            {!this.props.match.params.category && (
                              <div className="checkbox-filter__item">
                                <input className="custom-checkbox filter-check brand-check"
                                  type="checkbox"
                                  disabled='disabled'
                                  checked='checked'
                                />
                                <label className='pt-1'>
                                  {this.state.crumb}
                                </label>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    }
                    <div className="filter-block">
                      <Button variant="link filter-block__title" onClick={this.toggleFilter}>
                        <i className="i-arrow-b" />
                        Цена
                      </Button>
                      <div className="filter-block__inner">
                        {this.state.price_to &&
                          <div className="price-range">
                            <div className="price-range__wrap">
                              <label>от</label>
                              <input type="number" value={this.state.price_from} onChange={this.onLowerBoundChange} />
                              <label>до</label>
                              <input type="number" value={this.state.price_to} onChange={this.onUpperBoundChange} />
                              <i className="i-rub" />
                              <Button variant="primary" onClick={this.changePageAndHandle}>OK</Button>
                            </div>
                            <Range value={[this.state.price_from, this.state.price_to]} min={this.state.min} max={this.state.max} allowCross={false} onChange={this.onSliderChange} />
                          </div>
                        }
                      </div>
                    </div>
                    {this.state.filters.map((filter, index) => {
                      return (
                        <div className="filter-block" key={index}>
                          <Button variant="link filter-block__title" onClick={this.toggleFilter}>
                            <i className="i-arrow-b" />
                            {filter.name}
                          </Button>
                          <div className="filter-block__inner">
                            <div className="checkbox-filter">
                              {filter.filter_option.map((opt, index) => {
                                return (
                                  <div className="checkbox-filter__item" key={index}>
                                    <input className="custom-checkbox filter-check"
                                      type="checkbox"
                                      id={opt.filter_option_id}
                                      onChange={this.filterCheck} />
                                    <label htmlFor={opt.filter_option_id} >
                                      {opt.filter_option_value}
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    {this.state.colors.length > 0 && (
                      <div className="filter-block">
                        <Button variant="link filter-block__title" onClick={this.toggleFilter}>
                          <i className="i-arrow-b" />
                          Цвет
                        </Button>
                        <div className="filter-block__inner">
                          <div className="checkbox-filter">
                            {this.state.colors.map((color, index) => {
                              return (
                                <div className="checkbox-filter__item" key={index}>
                                  <input className="custom-checkbox color-check"
                                    type="checkbox"
                                    id={'c' + color.id}
                                    onChange={this.colorCheck} />
                                  <label htmlFor={color.id}>
                                    {color.name}
                                  </label>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    )
                    }
                  </div>
                  <div className="catalog-filter__footer flex">
                    <div className="filter-selection__label">
                      <p>Выбрано товаров: <span>{this.state.pagination && this.state.pagination.total}</span></p>
                    </div>
                    <Button className="filter-selection__btn reset" onClick={this.resetFilters}>Сбросить</Button>
                    <Button className="filter-set" onClick={this.changePageAndHandle}>Показать</Button>
                  </div>
                </div>
              </aside>
              <main className="promo-content" style={{
                transition: 'opacity .4s ease',
                opacity: this.state.isLoading ? .5 : 1,
                pointerEvents: this.state.isLoading ? 'none' : 'auto'
              }}>
                <div className="catalog-list">
                  <div className="catalog-list__wrap">
                    {(this.state.products && this.state.products.length > 0 && this.state.products.map((p, i) =>
                    (
                      <ProductCard
                        product={p}
                        key={i}
                        context={this.context}

                        isProductsHasVariable={this.state.isProductsHasVariable}

                      />
                    )
                    ))}
                  </div>
                </div>
                {this.state.pagination &&
                  (<div className="catalog-list__navigation">
                    {/*<div className="loadMore">
                                            <button>Показать больше</button>
                                        </div>*/}
                    {this.state.pagination.lastPage > 1 &&
                      <div className="pagination">
                        <ul className="pagination__wrap">
                          <ul className="pag_arrows">
                            <li className="pagination__item pag_arrows__item bt_pprev">
                              {this.state.page === 1 &&
                                <span style={{ opacity: .15 }}>
                                  <i className="i-arrow prev" style={{
                                    display: 'inline-block',
                                    transform: 'rotate(90deg) translateY(-14px)'
                                  }} />
                                  <i className="i-arrow prev"
                                    style={{ display: 'inline-block' }} />
                                </span>
                              }
                              {this.state.page !== 1 &&
                                <a href='!#' className={`link`} onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(1)
                                }}>
                                  <i className="i-arrow prev"
                                    style={{ display: 'inline-block', transform: 'rotate(90deg) translateY(-14px)' }} />
                                  <i className="i-arrow prev" style={{ display: 'inline-block' }} />
                                </a>
                              }
                            </li>
                            <li className="pagination__item pag_arrows__item bt_prev">
                              {this.state.page === 1 &&
                                <span style={{ opacity: .15 }}>
                                  <i className="i-arrow prev" style={{ opacity: .15 }} />
                                </span>
                              }
                              {this.state.page !== 1 &&
                                <a className={`link`} href='!#' onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(this.state.page > 1 ? this.state.page - 1 : 1)
                                }}>
                                  <i className="i-arrow prev" />
                                </a>
                              }
                            </li>
                          </ul>
                          <ul className="pag_list">
                            {this.state.page > 2 && this.state.pagination.lastPage > 3 &&
                              <li className="pagination__item">
                                <a href='!#' className="link" onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(1)
                                }}>
                                  <b>1</b>
                                </a>
                              </li>
                            }
                            {this.state.page > 3 && this.state.pagination.lastPage > 4 &&
                              <li className="pagination__item"><b>...</b></li>
                            }
                            {this.state.page === this.state.pagination.lastPage && this.state.pagination.lastPage > 2 &&
                              <li className="pagination__item">
                                <a href='!#' className="link" onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(this.state.page - 2)
                                }}>
                                  <b>{this.state.page * 1 - 2}</b>
                                </a>
                              </li>
                            }
                            {this.state.page > 1 &&
                              <li className="pagination__item">
                                <a href='!#' className="link" onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(this.state.page * 1 - 1)
                                }}>
                                  <b>{this.state.page * 1 - 1}</b>
                                </a>
                              </li>
                            }
                            <li className="pagination__item active">
                              <span className="link">
                                <b>{this.state.page}</b>
                              </span>
                            </li>
                            {this.state.page < this.state.pagination.lastPage &&
                              <li className="pagination__item">
                                <a href='!#' className="link" onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(this.state.page * 1 + 1)
                                }}>
                                  <b>{this.state.page * 1 + 1}</b>
                                </a>
                              </li>
                            }
                            {this.state.page === 1 && this.state.pagination.lastPage > 2 &&
                              <li className="pagination__item">
                                <a href='!#' className="link" onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(this.state.page * 1 + 2)
                                }}>
                                  <b>{this.state.page * 1 + 2}</b>
                                </a>
                              </li>
                            }

                            {this.state.page < this.state.pagination.lastPage * 1 - 2 && this.state.pagination.lastPage > 4 &&
                              <li className="pagination__item"><b>...</b></li>
                            }

                            {this.state.page < this.state.pagination.lastPage * 1 - 1 && this.state.pagination.lastPage > 3 &&
                              <li className="pagination__item">
                                <a href='!#' className="link" onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(this.state.pagination.lastPage)
                                }}>
                                  <b>{this.state.pagination.lastPage}</b>
                                </a>
                              </li>
                            }

                          </ul>
                          <ul className="pag_arrows">
                            <li className="pagination__item pag_arrows__item bt_next">
                              {this.state.page !== this.state.pagination.lastPage &&
                                <a href='!#' className={`link`} onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(this.state.page < this.state.pagination.lastPage ? this.state.page * 1 + 1 : this.state.pagination.lastPage)
                                }}>
                                  <i className="i-arrow next" />
                                </a>
                              }
                              {this.state.page === this.state.pagination.lastPage &&
                                <span className={`link`} style={{ opacity: .15 }}>
                                  <i className="i-arrow next" style={{ opacity: .15 }} />
                                </span>
                              }
                            </li>
                            <li className="pagination__item pag_arrows__item bt_nnext">
                              {this.state.page !== this.state.pagination.lastPage &&
                                <a href='!#' className={`link`} onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(this.state.pagination.lastPage)
                                }}>
                                  <i className="i-arrow next" style={{ display: 'inline-block' }} />
                                  <i className="i-arrow next"
                                    style={{ display: 'inline-block', transform: 'rotate(-90deg) translateY(-14px)' }} />
                                </a>
                              }
                              {this.state.page === this.state.pagination.lastPage &&
                                <span className={`link`} style={{ opacity: .15 }}>
                                  <i className="i-arrow next"
                                    style={{ display: 'inline-block' }} />
                                  <i className="i-arrow next" style={{
                                    display: 'inline-block',
                                    transform: 'rotate(-90deg) translateY(-14px)'
                                  }} />
                                </span>
                              }
                            </li>
                          </ul>
                        </ul>
                      </div>
                    }
                  </div>)}
                <div className="cat-desc">
                  {this.state.data && this.state.data.bottom_description !== 'null' && (
                    <div className="cat-desc__wrap"
                      dangerouslySetInnerHTML={{ __html: this.state.data.bottom_description }} />
                  )}
                </div>
              </main>
            </div>
          </Container>
        </main>
      </>
    );
  }
}

export default PagePromoCategory;
