import React, { useEffect, useRef, useState } from 'react'
import "@aarsteinmedia/dotlottie-player";
import { pathToLottieAnimation } from '../block-slider/utils';

const AnimationLayer = (props) => {

    const { animation_url, animation_speed, zIndex } = props

    const playerRef = useRef(null)
    const [animationURL, setAnimationURL] = useState(null);

    useEffect(() => {
        if (animation_url) handleLottieAnimationLoad()
    }, [animation_url]);

    const handleLottieAnimationLoad = () => {
        const lottieFileURL = animation_url ? `/blockspopup/${animation_url}` : null;
        setAnimationURL(lottieFileURL);
    }

    useEffect(() => {
        if (playerRef.current && animationURL) {
            waitForLottieAnimationLoaded().catch((err) => {
                console.log('lottie err', err)
                setAnimationURL(null)
            })
        }
    }, [playerRef.current, animationURL]);

    const waitForLottieAnimationLoaded = () => {
        return new Promise(() => {
            if (!playerRef?.current?.getLottie()) {
                throw new Error('animation error');
            }
        });
    }


    return (
        <div
            className="blocks-wrapper__animation-layer"
            style={{ zIndex: zIndex }}
        >
            {
                animation_url && animationURL !== null &&
                <dotlottie-player
                    ref={playerRef}
                    src={animationURL}
                    background="transparent"
                    speed={animation_speed}
                    direction="1"
                    mode="normal"
                    loop
                    autoplay
                ></dotlottie-player>
            }
        </div>
    )
}

export default AnimationLayer